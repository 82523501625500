@media (min-width: 500px) {
  .headerContainer {
    overflow: hidden;
    width: 100%;
    display: flex;
  }
}

@media (max-width: 499px) {
  .headerContainer {
    margin-top: 20px;
    overflow: hidden;
    width: 200%;
  }
}

@media (max-width: 290px) {
  .headerContainer {
    margin-top: 20px;
    overflow: hidden;
    width: 300%;
  }
}

.txt-btn-modal-content {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.principal {
  overflow: hidden;
}
