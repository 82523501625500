.contenedor {
  position: relative;
  bottom: 0;
  width: 100%;
}
.contenedor-img {
  background-color: #e11f1d;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  padding: 0;
  position: relative;
  padding-right: 1.5%;
}
